// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "vanilla-nested";


document.addEventListener('turbolinks:load', () => {
  document.getElementById('open-navigation').addEventListener('click', () => {
    document.querySelector('aside').classList.toggle('show')
  })

  document.getElementById('close-navigation').addEventListener('click', () => {
    document.querySelector('aside').classList.toggle('show')
  })

  var steps = document.getElementById('steps')
  if(steps) {
    steps.addEventListener('vanilla-nested:fields-added', function(e){
      var added_task = e.detail.added
      var stepCount = document.querySelectorAll('.step-fields').length
      added_task.querySelector('.step-order').value = stepCount - 1
      added_task.querySelector('.step-title').value = 'Step ' + stepCount
    })
  }
  
  document.addEventListener('vanilla-nested:fields-removed', function(e){
    e.target.style.display = 'none'
  })
  
  const request_project_dropdown = document.querySelector('#hours_request_request_project_id')

  if(request_project_dropdown){
    request_project_dropdown.addEventListener('change', (e) => {
      location.replace(`/hours_requests/new?request_project_id=${e.target.value}`)
    })
  } 
})
